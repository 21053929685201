// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-home-blog-index-js": () => import("./../../../src/components/Home/Blog/index.js" /* webpackChunkName: "component---src-components-home-blog-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-custom-url-js": () => import("./../../../src/pages/book/[customUrl].js" /* webpackChunkName: "component---src-pages-book-custom-url-js" */),
  "component---src-pages-bookappointment-js": () => import("./../../../src/pages/bookappointment.js" /* webpackChunkName: "component---src-pages-bookappointment-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-doctors-profile-js": () => import("./../../../src/pages/DoctorsProfile.js" /* webpackChunkName: "component---src-pages-doctors-profile-js" */),
  "component---src-pages-find-doctors-js": () => import("./../../../src/pages/FindDoctors.js" /* webpackChunkName: "component---src-pages-find-doctors-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/Media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/Register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-spot-pay-js": () => import("./../../../src/pages/spotPay.js" /* webpackChunkName: "component---src-pages-spot-pay-js" */)
}

